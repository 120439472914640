(function () {
  let wl = document.createElement("script");
  wl.type = "text/javascript";
  wl.src = "https://material.weiling.cn/h5/willing-third-party-js/v1/register-comp-v1.min.js";
  wl.id = "wlWXREG";
  wl.onload = function () {
    wlRegisterComp.init({
      wl_id: "wpW2WmDAAAjT9PP65h63Ilm7Xlbvg4qg", // 在创建智能码完成时获取
      wl_abbrev_name: "", // 网站企业名称（建议填写简称），非必填
      is_encrypt: false // 用户信息是否开启加密模式
    });
  };
  if (!document.getElementById("wlWXREG")) {
    let s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(wl, s);
  }
})();