import { createRouter, createWebHistory } from 'vue-router'
import store from '@/base/store'

// import Login from '../components/Login.vue'
const Login = () => import(/* webpackChunkName: "Login" */ '../components/Login.vue')
const Register = () => import(/* webpackChunkName: "Login" */ '../components/Register.vue')

const Home = () => import(/* webpackChunkName: "Home" */ '../components/Home.vue')
const HomePage = () => import('@/views/homepage/Index')

const UserViolationList = () =>
  import(/* webpackChunkName: "UserViolationList" */ '../components/users/violationList/UserViolationList.vue')

// const Recharge = () => import( /* webpackChunkName: "Recharge-RechargeRecord" */ '../components/recharge/Recharge.vue')
const QbitRecharge = () => import('@/views/finance/qbit/Index')
const payRecord = () => import(/* webpackChunkName: "Recharge-RechargeRecord" */ '../components/recharge/payRecord.vue')
const Invoice = () => import(/* webpackChunkName: "Invoice" */ '../components/recharge/Invoice.vue')
const Bill = () => import(/* webpackChunkName: "Bill" */ '../components/recharge/Bill.vue')
const Payment = () => import(/* webpackChunkName: "Recharge-RechargeRecord" */ '../components/recharge/Payment.vue')
const OperationLog = () =>
  import(/* webpackChunkName: "OperationLog-AccountHistory" */ '../components/operation/OperationLog.vue')

// 个人中心
const CompanyInfo = () => import(/* webpackChunkName: "Info-FeedBack" */ '../components/companyInfo/CompanyInfo.vue')
const ClientInfo = () => import('@/views/userinfo/approve/ClientInfo')
const NoticeManager = () =>
  import(/* webpackChunkName: "Info-FeedBack" */ '../components/companyInfo/NoticeManager.vue')
const questionnaire = () =>
  import(/* webpackChunkName: "Info-FeedBack" */ '../components/companyInfo/questionnaire.vue')
const questionDialog = () =>
  import(/* webpackChunkName: "Info-FeedBack" */ '../components/companyInfo/questionDialog.vue')
const Invite = () => import(/* webpackChunkName: "Info-FeedBack" */ '../components/companyInfo/Invite.vue')

// 学习中心
const LearnCenter = () => import(/*webpackChunkName:"LearnCenter"*/ '../components/learnCenter/LearnCenter.vue')

const FeedBack = () => import(/* webpackChunkName: "Info-FeedBack" */ '../components/feedback/FeedBack.vue')
const FeedBackDetail = () => import(/* webpackChunkName: "Info-FeedBack" */ '../components/feedback/FeedBackDetail.vue')
const EditInfo = () => import(/* webpackChunkName: "Info-FeedBack" */ '../components/companyInfo/EditInfo.vue')
const ForgetPsd = () => import(/* webpackChunkName: "Info-FeedBack" */ '../components/companyInfo/ForgetPsd.vue')
const Message = () => import(/* webpackChunkName: "Info-FeedBack" */ '../components/companyInfo/Message.vue')

const HelpCenter = () => import(/* webpackChunkName: "Help-Center" */ '../components/helpCenter/HelpCenter.vue')
const HelpHome = () => import(/* webpackChunkName: "Help-Center" */ '../components/helpCenter/HelpHome.vue')
const SearchResult = () => import(/* webpackChunkName: "Help-Center" */ '../components/helpCenter/SearchResult.vue')
const ArticleHome = () => import(/* webpackChunkName: "Help-Center" */ '../components/helpCenter/ArticleHome.vue')
const Index = () => import(/* webpackChunkName: "Help-Center" */ '../components/helpCenter/Index.vue')

const MemberInfo = () => import(/* webpackChunkName: "Member" */ '../components/member/MemberInfo.vue')
const MemberUpgrade = () => import(/* webpackChunkName: "Member" */ '../components/member/MemberUpgrade.vue')

// 数据仪表
const DataPanel = () => import(/* webpackChunkName: "Data-Panel" */ '../components/dataPanel/DataPanel.vue')
const AdCategory = () => import(/* webpackChunkName: "Data-Panel" */ '../components/dataPanel/AdCategory.vue')

//开户管理
const AccountManage = () =>
  import(/* webpackChunkName: "Account-Manage" */ '../components/accountManage/AccountManage.vue')
const AccountHistory = () =>
  import(/* webpackChunkName: "Account-Manage" */ '../components/accountManage/AccountHistory.vue')

// 账户管理
const ListAccount = () => import(/* webpackChunkName: "Account-Manage" */ '../components/accountKeeper/ListAccount.vue')

// 广告数据列表
const ReportList = () => import(/* webpackChunkName: "Report" */ '../components/reportList/ReportList.vue')
const ReportRecord = () => import(/* webpackChunkName: "Report" */ '../components/reportList/ReportRecord.vue')

// 智能素材
const ImgFodder = () => import(/* webpackChunkName: "Fodder" */ '../components/fodder/ImgFodder.vue')
const FodderWork = () => import(/* webpackChunkName: "Fodder" */ '../components/fodder/FodderWork.vue')
const FodderApply = () => import(/* webpackChunkName: "Fodder" */ '../components/fodder/FodderApply.vue')
const FodderHistory = () => import(/* webpackChunkName: "Fodder" */ '../components/fodder/FodderHistory.vue')
const MaterialHome = () => import(/* webpackChunkName: "Fodder" */ '../components/fodder/material/MaterialHome.vue')
const MaterialMake = () => import(/* webpackChunkName: "Fodder" */ '../components/fodder/material/MaterialMake.vue')
const MaterialForm = () => import(/* webpackChunkName: "Fodder" */ '../components/fodder/material/MaterialForm.vue')
const MaterialSelect = () => import(/* webpackChunkName: "Fodder" */ '../components/fodder/material/MaterialSelect.vue')
const MaterialTemplate = () =>
  import(/* webpackChunkName: "Fodder" */ '../components/fodder/material/MaterialTemplate.vue')
const MaterialResult = () => import(/* webpackChunkName: "Fodder" */ '../components/fodder/material/MaterialResult.vue')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:pathMatch(.*)',
      //访问不存在路由的时候 重定向到首页
      redirect: '/homepage'
    },
    {
      path: '/',
      redirect: '/homepage'
    },
    {
      path: '/login',
      component: Login,
      meta: {
        title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
        content: {
          keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
          description:
            'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
        }
      }
    },
    {
      path: '/register',
      component: Register,
      meta: {
        title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
        content: {
          keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
          description:
            'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
        }
      }
    },
    {
      path: '/wj',
      component: questionnaire,
      meta: {
        title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
        content: {
          keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
          description:
            'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
        }
      }
    },
    {
      path: '/wj1',
      component: questionDialog,
      meta: {
        title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
        content: {
          keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
          description:
            'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
        }
      }
    },
    {
      path: '/error/:code',
      component: () => import('@/views/base/ErrorPage'),
      meta: { title: '404' }
    },
    {
      path: '/hc',
      component: HelpCenter,
      name: 'hc',
      redirect: '/hc/helpHome',
      children: [
        {
          path: 'search',
          component: SearchResult,
          name: 'search',
          meta: {
            title: '帮助中心-Facebook广告使用技巧及常见问题解答-MMCloud',
            content: {
              keywords: 'Facebook广告常见问题，Facebook开户，Facebook代理，，易诺',
              description:
                'MMCloud帮助中心是Facebook代理商为进行Facebook广告投放和使用的用户提供的常见问题解答。涵盖Facebook开户前须知，Facebook广告投放流程，Facebook广告申诉步骤，Facebook主页，Facebook商务管理平台常见问题及的使用方法。'
            }
          },
          props($route) {
            return {
              query: $route.query.query
              // page: $route.query.page,
            }
          }
        },
        {
          path: 'helpHome',
          component: HelpHome,
          name: 'helpHome',
          meta: {
            title: '帮助中心-Facebook广告使用技巧及常见问题解答-MMCloud',
            content: {
              keywords: 'Facebook广告常见问题，Facebook开户，Facebook代理，，易诺',
              description:
                'MMCloud帮助中心是Facebook代理商为进行Facebook广告投放和使用的用户提供的常见问题解答。涵盖Facebook开户前须知，Facebook广告投放流程，Facebook广告申诉步骤，Facebook主页，Facebook商务管理平台常见问题及的使用方法。'
            }
          }
        }
      ]
    },
    // {
    //   path: '/helpCenter',
    //   component: ArticleHome,
    //   redirect: '/help/home',
    //   children: [
    //     {
    //       name: 'help',
    //       path: '/help/:id',
    //       component: Index
    //     }
    //   ]
    // },

    {
      path: '/reset',
      component: EditInfo,
      meta: {
        title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
        content: {
          keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
          description:
            'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
        }
      }
    },
    {
      path: '/forgetReset',
      component: ForgetPsd,
      meta: {
        title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
        content: {
          keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
          description:
            'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
        }
      }
    },
    {
      path: '/home',
      component: Home,
      redirect: '/homepage',
      children: [
        {
          path: '/homepage',
          component: HomePage,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          name: 'advertising_list',
          path: '/advertising_list',
          component: ListAccount,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          },
          props($route) {
            return {
              tabsType: $route.query.type
            }
          }
        },
        {
          path: '/violation_local_list',
          component: UserViolationList,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/recharge',
          component: () => import('@/views/finance/recharge/Index'),
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/QbitRecharge',
          component: QbitRecharge,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/recharge_list',
          component: payRecord,
          meta: {
            title: 'MMCloud：海外广告管理平台- Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/operationRecord',
          component: OperationLog,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/users',
          component: CompanyInfo,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/invite',
          component: Invite,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/learnCenter',
          name: 'LearnCenter',
          component: LearnCenter,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/feedback',
          component: FeedBack,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/feedbackDetail',
          component: FeedBackDetail,
          meta: {
            title: 'MMCloud：海外广告管理平台- Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/clientInfo',
          component: ClientInfo,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/subMsg',
          component: NoticeManager,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/message',
          component: Message,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/invoice',
          component: Invoice,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/bill',
          component: Bill,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          name: 'payment',
          path: '/payment',
          component: Payment,
          props($route) {
            return {
              id: $route.query.payid
            }
          },
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          name: 'paymentCheckout',
          path: '/payment/checkout/:type/:id',
          component: () => import('@/views/finance/checkout/Index'),
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商MMCloud易诺为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        // {
        //   path: '/member',
        //   component: MemberInfo,
        //   meta: {
        //     title: 'YinoCloud易诺云：海外广告管理平台-Facebook开户-海外推广平台',
        //     content: {
        //       keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
        //       description:
        //         'YinoCloud易诺云是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账',
        //     },
        //   },
        // },
        {
          path: '/upgrade',
          component: MemberUpgrade,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/panel',
          component: DataPanel,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/adcate',
          component: AdCategory,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          path: '/open_account',
          component: AccountManage,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          name: 'account_history',
          path: '/account_history',
          component: AccountHistory,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          },
          props($route) {
            return {
              tabsType: $route.query.type
            }
          }
        },
        {
          name: 'ReportList',
          path: '/reportList',
          component: ReportList,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          name: 'ReportRecord',
          path: '/reportRecord',
          component: ReportRecord,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        // 智能素材
        {
          name: 'ImgFodder',
          path: '/imgFodder',
          component: ImgFodder,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          name: 'FodderHistory',
          path: '/fodderHistory',
          component: FodderHistory,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          name: 'MaterialHome',
          path: '/materialHome',
          component: MaterialHome,
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          }
        },
        {
          name: 'MaterialMake',
          path: '/materialMake',
          component: MaterialMake,
          redirect: '/materialMake/MaterialForm',
          meta: {
            title: 'MMCloud：海外广告管理平台-Facebook开户-海外推广平台',
            content: {
              keywords: '海外广告管理平台，Facebook开户，海外推广平台，海外营销平台，跨境营销平台',
              description:
                'MMCloud是Facebook代理商为解决海外广告投放痛点推出的海外广告管理平台。能够帮助企业高效进行海外推广，实现一键自助Facebook开户，人民币美金双币种充值Facebook广告账户实时到账'
            }
          },
          children: [
            {
              name: 'MaterialForm',
              path: 'materialForm',
              component: MaterialForm,
              props($route) {
                return {
                  queryId: $route.query.id
                }
              }
            },
            {
              name: 'MaterialSelect',
              path: 'materialSelect',
              component: MaterialSelect,
              props($route) {
                return {
                  urlId: $route.query.id
                }
              }
            },
            {
              name: 'MaterialTemplate',
              path: 'materialTemplate',
              component: MaterialTemplate,
              props($route) {
                console.log($route.query.urlId)
                return {
                  imgId: $route.query.id,
                  urlId: $route.query.urlId
                }
              }
            },
            {
              name: 'MaterialResult',
              path: 'materialResult',
              component: MaterialResult,
              props($route) {
                return {
                  urlId: $route.query.id
                }
              }
            }
          ]
        }
      ]
    }
  ]
})

// 路由导航守卫
router.beforeEach((to, from, next) => {
  // var userInfo = window.localStorage.getItem('userInfo')
  // console.log(JSON.parse(userInfo));

  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }
  //    路由发生变化改变description和keyword
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }

  //嵌入iframe
  if (window.frames.length !== parent.frames.length) {
    if (to.path === '/adcate' && to.query && to.query.auth_type && to.query.auth_token) {
      localStorage.setItem('auth_token', to.query.auth_token)
      return next()
    }
  }

  if (to.path === '/login' || to.path === '/register') return next()
  if (to.path === '/forgetReset') return next()
  if (to.path.slice(0, 3) === '/hc') return next()
  if (to.path.slice(0, 11) === '/helpCenter') return next()
  if (to.path.slice(0, 5) === '/help') return next()
  if (to.path.slice(0, 3) === '/wj') return next()
  if (to.path.indexOf('/error/') >= 0) return next()

  const userInfo = store.getters.userInfo
  if (!userInfo || userInfo === 'undefined' || !userInfo.id) return next('/login')
  next()
})

router.afterEach((to, from, next) => {
  setTimeout(() => {
    // 此处粘贴百度统计复制过来的代码
    var _hmt = _hmt || []
    ;(function() {
      var hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?3c68151202550ac0819b313105577b87'
      var s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    })()
  }, 0)
})

export default router
