(function(a, b, c, d, e, j, s) {
  a[d] = a[d] || function() {
    (a[d].a = a[d].a || []).push(arguments)
  };
  j = b.createElement(c),
    s = b.getElementsByTagName(c)[0];
  j.async = true;
  j.charset = 'UTF-8';
  j.src = 'https://static.meiqia.com/widget/loader.js';
  s.parentNode.insertBefore(j, s);
})(window, document, 'script', '_MEIQIA');

_MEIQIA('entId', 'e0a1a0c58e051e84fb802fa6626db757');
_MEIQIA('withoutBtn')