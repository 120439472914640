import filters from '@/base/filters/base'
export default {
  namespace: 'true',
  state() {
    return {
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      token: localStorage.getItem('token'),
      loading: false,
    }
  },
  mutations: {
    async showLoading(state, loading) {
      state.loading = loading
    },
    async changeUserToken(state, user) {
      state.user = user
      if (undefined === user || undefined === user.token) {
        state.token = undefined
        localStorage.removeItem('token')
      } else {
        state.token = user.token
        localStorage.setItem('token', user.token)
      }
    },
  },
  actions: {
    EDIT_USERINFO({commit}, user) {
      return new Promise((res) => {
        commit('changeUserToken', user)
        if (undefined === user) {
          localStorage.removeItem('user')
          if (_MEIQIA) _MEIQIA('metadata', {})
        } else {
          localStorage.setItem('user', JSON.stringify(user))

          if (_MEIQIA) _MEIQIA('metadata', {
            id: user.id,
            name: user.name ? user.name : '',
            address: (user.company ? user.company : '') + (user.crm_id ? ' - 已认证' : ' - 未认证'),
            tel: user.phone ? user.phone : '',
            comment: `会员积分：${user.integral ? user.integral : 0}；会员等级：${filters.getVipLevel(user.integral)}`
          })
        }
        res()
      })
    },
    EDIT_LOADING({commit}, loading) {
      return new Promise((res) => {
        commit('showLoading', loading)
        res()
      })
    },
  },
  getters: {
    isLoading: (state) => {
      return state.loading
    },
    isLogged: (state) => {
      if (state.user && state.user.name && state.token) return true
      return false
    },
    tokenInfo: (state) => {
      return state.token
    },
    userInfo: (state) => {
      return state.user
    }
  }
}