<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  mounted() {
    this.editApp()
  },
  methods: {
    editApp() {
      if (this.$route.name == 'help') {
         document.querySelector('#app').firstChild.style.minWidth = 'auto'
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
