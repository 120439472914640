const base = {
  thousands: (num) => {
    if (!num) return num
    let str = num.toString()
    let reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
    return str.replace(reg, '$1,')
  },
  getVipLevel: (grade) => {
    if (!grade || grade < 2000) return 'V1 水手'
    if (grade < 5000) return 'V2 三副'
    if (grade < 20000) return 'V3 二副'
    if (grade < 50000) return 'V4 大副'
    return 'V5 船长'
  },
  downloadFile: (file) => {
    window.open(`document/${file}`)
  },
  showPdf: (file) => {
    window.open(file)
  },
  gotoPage: (url, type = '_blank') => {
    window.open(url, type)
  },
  reloadPage: () => {
    window.location.reload()
  },
  getDefaultDuration(diff = 7, type = 'day') {
    if (type === 'day') {
      let start = new Date()
      let end = new Date()
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      return [Math.round((start.getTime() - 3600 * 1000 * 24 * diff) / 1000), Math.round(end.getTime() / 1000)]
    }
    return null
  },
  getBrowserInfo() {
    let sysObj = {}
    let browserInfo = navigator.userAgent.toLowerCase()
    let regular = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
    let vsMsg = browserInfo.match(regular)
    sysObj.browserName = vsMsg[1].replace(/version/, "'safari")
    sysObj.versionInfo = vsMsg[2]
    return sysObj
  },
  // 获取操作系统信息
  getOsInfo() {
    var userAgent = navigator.userAgent.toLowerCase()
    var name = 'Unknown'
    var version = 'Unknown'
    if (userAgent.indexOf('win') > -1) {
      name = 'Windows'
      if (userAgent.indexOf('windows nt 5.0') > -1) {
        version = 'Windows 2000'
      } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
        version = 'Windows XP'
      } else if (userAgent.indexOf('windows nt 6.0') > -1) {
        version = 'Windows Vista'
      } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
        version = 'Windows 7'
      } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
        version = 'Windows 8'
      } else if (userAgent.indexOf('windows nt 6.3') > -1) {
        version = 'Windows 8.1'
      } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
        version = 'Windows 10'
      } else {
        version = 'Unknown'
      }
    } else if (userAgent.indexOf('iphone') > -1) {
      name = 'Iphone'
    } else if (userAgent.indexOf('mac') > -1) {
      name = 'Mac'
    } else if (
      userAgent.indexOf('x11') > -1 ||
      userAgent.indexOf('unix') > -1 ||
      userAgent.indexOf('sunname') > -1 ||
      userAgent.indexOf('bsd') > -1
    ) {
      name = 'Unix'
    } else if (userAgent.indexOf('linux') > -1) {
      if (userAgent.indexOf('android') > -1) {
        name = 'Android'
      } else {
        name = 'Linux'
      }
    } else {
      name = 'Unknown'
    }
    return { name, version }
  },
  transSortOrder(sort) {
    if (sort === 'ascending') return 2
    if (sort === 'descending') return 1
    return 0
  },
  getDatePickerShortcuts() {
    return (
      {
        text: '昨天',
        value: () => {
          const start = new Date()
          const end = new Date()
          start.setTime(start.getTime() - 8.64e7)
          end.setTime(end.getTime() - 8.64e7)
          return [start, end]
        },
      },
      {
        text: '近7天',
        value: () => {
          const start = new Date()
          const end = new Date()
          start.setTime(start.getTime() - 8.64e7 * 7)
          end.setTime(end.getTime() - 8.64e7)
          return [start, end]
        },
      },
      {
        text: '近30天',
        value: () => {
          const start = new Date()
          const end = new Date()
          start.setTime(start.getTime() - 8.64e7 * 30)
          end.setTime(end.getTime() - 8.64e7)
          return [start, end]
        },
      },
      {
        text: '近90天',
        value: () => {
          const start = new Date()
          const end = new Date()
          start.setTime(start.getTime() - 8.64e7 * 90)
          end.setTime(end.getTime() - 8.64e7)
          return [start, end]
        },
      }
    )
  },
  getDefaultDisabledDate(time) {
    //2022-01-01之前 或今天以后的日期，禁止选择
    return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now() - 8.64e7
  },
}

export default base
